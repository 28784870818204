import lodashMapKeys from 'lodash-es/mapKeys';
import lodashOmit from 'lodash-es/omit';
import { FetchError } from 'ofetch';

export type RequestOption = {
    headers?: Record<string, string>;
    signal?: AbortSignal;
};

export type RequestConfig = RequestOption & {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    params?: Record<string, any>;
    data?: BodyType<unknown>;
};

export const fetchApiInstance = <ResponseType>(
    { url, method, params, data, headers = {}, signal }: RequestConfig,
    options?: RequestOption
): Promise<ResponseType> => {
    options = options ?? {};
    const runtimeConfig = useRuntimeConfig();
    const { token } = useAuthUtils();

    // Remove Content-Type header as suggested in github issue https://github.com/unjs/ofetch/issues/37 ofetch insert it automatically
    headers = {
        ...(token.value ? { Authorization: token.value } : {}),
        ...lodashOmit(headers, ['Content-Type']),
        ...(options && options?.headers ? lodashOmit(options?.headers, ['Content-Type']) : {}),
    };

    return $fetch(url, {
        baseURL: runtimeConfig.public.backendApiBaseUrl,
        method,
        query: lodashMapKeys(params, (value, key) => (Array.isArray(value) ? `${key}[]` : key)),
        headers,
        ...(data ? { body: data } : {}),
        signal: options?.signal ?? signal,
    });
};

export default fetchApiInstance;

export type ErrorType<ErrorData> = FetchError<ErrorData>;

export type BodyType<BodyData> = BodyData;
