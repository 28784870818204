import type { ComputedRef, WritableComputedRef } from 'vue';

import { FIRST_PAGE, ITEMS_PER_PAGE, FE_PAGE_PARAM } from '~/constants/pagination';

interface PaginationApiParams {
    itemsPerPage?: number;
    page?: number;
}

export interface UsePaginationParamsReturn {
    page: WritableComputedRef<number>;
    itemsPerPage: number;
    paginationApiParams: ComputedRef<PaginationApiParams>;
}

export const usePaginationParams = (itemsPerPage: number = ITEMS_PER_PAGE): UsePaginationParamsReturn => {
    const route = useRoute();

    const page = computed<number>({
        get: () => locationQueryAsOptionalNumber(route.query[FE_PAGE_PARAM]) ?? FIRST_PAGE,
        set(value) {
            const queryValue = value > FIRST_PAGE ? value : undefined;

            navigateTo({
                query: {
                    ...route.query,
                    [FE_PAGE_PARAM]: queryValue,
                },
            });
        },
    });

    const paginationApiParams = computed<PaginationApiParams>(() => ({
        ...(page.value !== FIRST_PAGE && { page: page.value }),
        itemsPerPage,
    }));

    return {
        page,
        itemsPerPage,
        paginationApiParams,
    };
};
