import {
  useQuery
} from '@tanstack/vue-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AdminUserEntity,
  ApiGetAdminUsersParams,
  BackendErrorEntity
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiGetAdminUsers = (
    params?: MaybeRef<ApiGetAdminUsersParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<AdminUserEntity[]>(
      {url: `/adminUsers`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiGetAdminUsersQueryKey = (params?: MaybeRef<ApiGetAdminUsersParams>,) => {
    return ['adminUsers', ...(params ? [params]: [])] as const;
    }

    
export const getApiGetAdminUsersQueryOptions = <TData = Awaited<ReturnType<typeof apiGetAdminUsers>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiGetAdminUsersParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAdminUsers>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetAdminUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetAdminUsers>>> = () => apiGetAdminUsers(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetAdminUsers>>, TError, TData> 
}

export type ApiGetAdminUsersQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetAdminUsers>>>
export type ApiGetAdminUsersQueryError = ErrorType<BackendErrorEntity>



export function useApiGetAdminUsers<TData = Awaited<ReturnType<typeof apiGetAdminUsers>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiGetAdminUsersParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAdminUsers>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetAdminUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



