import lodashToNumber from 'lodash-es/toNumber';
import lodashToString from 'lodash-es/toString';
import type { LocationQuery } from 'vue-router';

export const locationQueryAsOptionalString = (value: LocationQuery[string]): string | undefined => (value ? lodashToString(value) : undefined);

export const locationQueryAsOptionalNumber = (value: LocationQuery[string]): number | undefined =>
    Number.isFinite(+(value ?? '')) && lodashToString(value).length > 0 ? lodashToNumber(value) : undefined;

export const locationQueryAsStringArray = (value: LocationQuery[string]): string[] =>
    (Array.isArray(value) ? value : [value]).map(v => (v ? lodashToString(v) : undefined)).filter(v => typeof v === 'string') as string[];

export const paramAsOptionalString = (value: string | string[]): string | undefined => (value ? lodashToString(value) : undefined);
