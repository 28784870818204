import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  BackendErrorEntity,
  CreateVersionDto,
  UpdateVersionDto,
  VersionEntity
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiCreateVersion = (
    createVersionDto: MaybeRef<CreateVersionDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createVersionDto = unref(createVersionDto);
      
      return fetchApiInstance<VersionEntity>(
      {url: `/versions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createVersionDto
    },
      options);
    }
  


export const getApiCreateVersionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateVersion>>, TError,{data: BodyType<CreateVersionDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateVersion>>, TError,{data: BodyType<CreateVersionDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateVersion>>, {data: BodyType<CreateVersionDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateVersion(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateVersionMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateVersion>>>
    export type ApiCreateVersionMutationBody = BodyType<CreateVersionDto>
    export type ApiCreateVersionMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateVersion = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateVersion>>, TError,{data: BodyType<CreateVersionDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateVersion>>,
        TError,
        {data: BodyType<CreateVersionDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetVersions = (
    
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      
      
      return fetchApiInstance<VersionEntity[]>(
      {url: `/versions`, method: 'GET'
    },
      options);
    }
  

export const getApiGetVersionsQueryKey = () => {
    return ['versions'] as const;
    }

    
export const getApiGetVersionsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetVersions>>, TError = ErrorType<BackendErrorEntity>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetVersions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetVersionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetVersions>>> = () => apiGetVersions(requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetVersions>>, TError, TData> 
}

export type ApiGetVersionsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetVersions>>>
export type ApiGetVersionsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetVersions<TData = Awaited<ReturnType<typeof apiGetVersions>>, TError = ErrorType<BackendErrorEntity>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetVersions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetVersionsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiGetVersion = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<VersionEntity>(
      {url: `/versions/${id}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetVersionQueryKey = (id: MaybeRef<string | undefined | null>,) => {
    return ['versions',id] as const;
    }

    
export const getApiGetVersionQueryOptions = <TData = Awaited<ReturnType<typeof apiGetVersion>>, TError = ErrorType<BackendErrorEntity>>(id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetVersion>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetVersionQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetVersion>>> = () => apiGetVersion(id, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetVersion>>, TError, TData> 
}

export type ApiGetVersionQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetVersion>>>
export type ApiGetVersionQueryError = ErrorType<BackendErrorEntity>



export function useApiGetVersion<TData = Awaited<ReturnType<typeof apiGetVersion>>, TError = ErrorType<BackendErrorEntity>>(
 id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetVersion>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetVersionQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateVersion = (
    id: MaybeRef<string | undefined | null>,
    updateVersionDto: MaybeRef<UpdateVersionDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
updateVersionDto = unref(updateVersionDto);
      
      return fetchApiInstance<VersionEntity>(
      {url: `/versions/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateVersionDto
    },
      options);
    }
  


export const getApiUpdateVersionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateVersion>>, TError,{id: string;data: BodyType<UpdateVersionDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateVersion>>, TError,{id: string;data: BodyType<UpdateVersionDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateVersion>>, {id: string;data: BodyType<UpdateVersionDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  apiUpdateVersion(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateVersionMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateVersion>>>
    export type ApiUpdateVersionMutationBody = BodyType<UpdateVersionDto>
    export type ApiUpdateVersionMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateVersion = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateVersion>>, TError,{id: string;data: BodyType<UpdateVersionDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateVersion>>,
        TError,
        {id: string;data: BodyType<UpdateVersionDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteVersion = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<VersionEntity>(
      {url: `/versions/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getApiDeleteVersionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteVersion>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteVersion>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteVersion>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  apiDeleteVersion(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteVersionMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteVersion>>>
    
    export type ApiDeleteVersionMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteVersion = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteVersion>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteVersion>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getApiDeleteVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    