import { unref } from 'vue';

import { apiGetBusDossier, type BackendErrorEntity, type ErrorType, useApiGetBusDossier } from '~/apiClient';

export const useApiOfflineGetBusDossier = <TData = Awaited<ReturnType<typeof apiGetBusDossier>>, TError = ErrorType<BackendErrorEntity>>(
    busDossierId?: Parameters<typeof useApiGetBusDossier<TData, TError>>[0],
    options?: Parameters<typeof useApiGetBusDossier<TData, TError>>[1]
): ReturnType<typeof useApiGetBusDossier<TData, TError>> => {
    const { queryOptions } = useApiOfflineUtils();

    return useApiGetBusDossier(busDossierId, {
        // @ts-expect-error
        query: {
            ...options?.query,
            ...queryOptions,
            queryFn: () => {
                const id = unref(busDossierId);

                if (id && isOfflineBusDossierId(id)) {
                    return optimisticBusDossier(id, { vin: offileBusDossierVin(id) });
                }

                return apiGetBusDossier(busDossierId, options?.request);
            },
        },
    });
};
