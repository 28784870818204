import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  BackendErrorEntity,
  BrandEntity,
  CreateBrandDto,
  UpdateBrandDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiCreateBrand = (
    createBrandDto: MaybeRef<CreateBrandDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createBrandDto = unref(createBrandDto);
      
      return fetchApiInstance<BrandEntity>(
      {url: `/brands`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBrandDto
    },
      options);
    }
  


export const getApiCreateBrandMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateBrand>>, TError,{data: BodyType<CreateBrandDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateBrand>>, TError,{data: BodyType<CreateBrandDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateBrand>>, {data: BodyType<CreateBrandDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateBrand(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateBrandMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateBrand>>>
    export type ApiCreateBrandMutationBody = BodyType<CreateBrandDto>
    export type ApiCreateBrandMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateBrand = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateBrand>>, TError,{data: BodyType<CreateBrandDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateBrand>>,
        TError,
        {data: BodyType<CreateBrandDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateBrandMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetBrands = (
    
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      
      
      return fetchApiInstance<BrandEntity[]>(
      {url: `/brands`, method: 'GET'
    },
      options);
    }
  

export const getApiGetBrandsQueryKey = () => {
    return ['brands'] as const;
    }

    
export const getApiGetBrandsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetBrands>>, TError = ErrorType<BackendErrorEntity>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBrands>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetBrandsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetBrands>>> = () => apiGetBrands(requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetBrands>>, TError, TData> 
}

export type ApiGetBrandsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetBrands>>>
export type ApiGetBrandsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetBrands<TData = Awaited<ReturnType<typeof apiGetBrands>>, TError = ErrorType<BackendErrorEntity>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBrands>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetBrandsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiGetBrand = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<BrandEntity>(
      {url: `/brands/${id}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetBrandQueryKey = (id: MaybeRef<string | undefined | null>,) => {
    return ['brands',id] as const;
    }

    
export const getApiGetBrandQueryOptions = <TData = Awaited<ReturnType<typeof apiGetBrand>>, TError = ErrorType<BackendErrorEntity>>(id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBrand>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetBrandQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetBrand>>> = () => apiGetBrand(id, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetBrand>>, TError, TData> 
}

export type ApiGetBrandQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetBrand>>>
export type ApiGetBrandQueryError = ErrorType<BackendErrorEntity>



export function useApiGetBrand<TData = Awaited<ReturnType<typeof apiGetBrand>>, TError = ErrorType<BackendErrorEntity>>(
 id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetBrand>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetBrandQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateBrand = (
    id: MaybeRef<string | undefined | null>,
    updateBrandDto: MaybeRef<UpdateBrandDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
updateBrandDto = unref(updateBrandDto);
      
      return fetchApiInstance<BrandEntity>(
      {url: `/brands/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateBrandDto
    },
      options);
    }
  


export const getApiUpdateBrandMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBrand>>, TError,{id: string;data: BodyType<UpdateBrandDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBrand>>, TError,{id: string;data: BodyType<UpdateBrandDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateBrand>>, {id: string;data: BodyType<UpdateBrandDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  apiUpdateBrand(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateBrandMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateBrand>>>
    export type ApiUpdateBrandMutationBody = BodyType<UpdateBrandDto>
    export type ApiUpdateBrandMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateBrand = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateBrand>>, TError,{id: string;data: BodyType<UpdateBrandDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateBrand>>,
        TError,
        {id: string;data: BodyType<UpdateBrandDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateBrandMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteBrand = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<BrandEntity>(
      {url: `/brands/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getApiDeleteBrandMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBrand>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBrand>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteBrand>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  apiDeleteBrand(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteBrandMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteBrand>>>
    
    export type ApiDeleteBrandMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteBrand = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteBrand>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteBrand>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getApiDeleteBrandMutationOptions(options);

      return useMutation(mutationOptions);
    }
    