import type { QueryKey } from '@tanstack/vue-query';
import { useQueryClient } from '@tanstack/vue-query';

import {
    apiGetBusDossiers,
    type BackendErrorEntity,
    type BusDossierEntity,
    type ErrorType,
    getApiGetBusDossierQueryKey,
    useApiGetBusDossiers,
} from '~/apiClient';

export const getApiOfflineGetBusDossiersQueryKey = (): QueryKey => {
    return ['offline', 'busDossiers'] as const;
};

export const useApiOfflineGetBusDossiers = <TData = Awaited<ReturnType<typeof apiGetBusDossiers>>, TError = ErrorType<BackendErrorEntity>>(
    params?: Parameters<typeof useApiGetBusDossiers<TData, TError>>[0],
    options?: Parameters<typeof useApiGetBusDossiers<TData, TError>>[1]
): ReturnType<typeof useApiGetBusDossiers<TData, TError>> => {
    const { queryOptions } = useApiOfflineUtils();
    const queryClient = useQueryClient();

    return useApiGetBusDossiers<TData, TError>(params, {
        ...options,
        // @ts-ignore
        query: {
            ...options?.query,
            ...queryOptions,
            queryFn: async () => {
                const { request: requestOptions } = options ?? {};

                return apiGetBusDossiers(params, requestOptions).then(data => {
                    data.busDossiers.map(bd =>
                        queryClient.setQueryData(getApiGetBusDossierQueryKey(bd.id), (old: BusDossierEntity) => ({
                            ...(old ?? {}),
                            ...bd,
                        }))
                    );

                    return data;
                });
            },
        },
    });
};
