<script setup lang="ts">
import {
    useApiGetAdminUsers,
    useApiGetBrands,
    useApiGetCompanies,
    useApiGetModels,
    useApiGetVersions,
    useApiListBusFactoryAttributes,
    useApiOfflineGetBusDossiers,
} from '~/apiClient';

// COMPOSABLES
const { data: session } = useAuthUtils();
const { paginationApiParams } = usePaginationParams();
useApiGetCompanies();
useApiGetAdminUsers();
useApiGetBrands();
useApiGetModels();
useApiGetVersions();
useApiListBusFactoryAttributes();

const { data: busDossiers } = useApiOfflineGetBusDossiers({ ...paginationApiParams.value, assigneeId: session.value?.id });

// DATA
const busDossierIds = computed<string[]>(() => busDossiers.value?.busDossiers.map(dossier => dossier.id) ?? []);
</script>
<template>
    <div class="invisible">
        <BusDossierOfflineIcons />
        <NuxtLink :to="{ name: 'bus-dossiers' }" />
        <NuxtLink :to="{ name: 'bus-dossiers-create' }" />
        <BusDossierOfflineLink v-for="id in busDossierIds" :id="id" :key="id" />
    </div>
</template>
