import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  BackendErrorEntity,
  CreateModelDto,
  ModelEntity,
  UpdateModelDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiCreateModel = (
    createModelDto: MaybeRef<CreateModelDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createModelDto = unref(createModelDto);
      
      return fetchApiInstance<ModelEntity>(
      {url: `/models`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createModelDto
    },
      options);
    }
  


export const getApiCreateModelMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateModel>>, TError,{data: BodyType<CreateModelDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateModel>>, TError,{data: BodyType<CreateModelDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateModel>>, {data: BodyType<CreateModelDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateModel(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateModelMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateModel>>>
    export type ApiCreateModelMutationBody = BodyType<CreateModelDto>
    export type ApiCreateModelMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateModel = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateModel>>, TError,{data: BodyType<CreateModelDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateModel>>,
        TError,
        {data: BodyType<CreateModelDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetModels = (
    
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      
      
      return fetchApiInstance<ModelEntity[]>(
      {url: `/models`, method: 'GET'
    },
      options);
    }
  

export const getApiGetModelsQueryKey = () => {
    return ['models'] as const;
    }

    
export const getApiGetModelsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetModels>>, TError = ErrorType<BackendErrorEntity>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetModels>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetModelsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetModels>>> = () => apiGetModels(requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetModels>>, TError, TData> 
}

export type ApiGetModelsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetModels>>>
export type ApiGetModelsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetModels<TData = Awaited<ReturnType<typeof apiGetModels>>, TError = ErrorType<BackendErrorEntity>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetModels>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetModelsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiGetModel = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<ModelEntity>(
      {url: `/models/${id}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetModelQueryKey = (id: MaybeRef<string | undefined | null>,) => {
    return ['models',id] as const;
    }

    
export const getApiGetModelQueryOptions = <TData = Awaited<ReturnType<typeof apiGetModel>>, TError = ErrorType<BackendErrorEntity>>(id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetModel>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetModelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetModel>>> = () => apiGetModel(id, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetModel>>, TError, TData> 
}

export type ApiGetModelQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetModel>>>
export type ApiGetModelQueryError = ErrorType<BackendErrorEntity>



export function useApiGetModel<TData = Awaited<ReturnType<typeof apiGetModel>>, TError = ErrorType<BackendErrorEntity>>(
 id: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetModel>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetModelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateModel = (
    id: MaybeRef<string | undefined | null>,
    updateModelDto: MaybeRef<UpdateModelDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
updateModelDto = unref(updateModelDto);
      
      return fetchApiInstance<ModelEntity>(
      {url: `/models/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateModelDto
    },
      options);
    }
  


export const getApiUpdateModelMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateModel>>, TError,{id: string;data: BodyType<UpdateModelDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateModel>>, TError,{id: string;data: BodyType<UpdateModelDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateModel>>, {id: string;data: BodyType<UpdateModelDto>}> = (props) => {
          const {id,data} = props ?? {};

          return  apiUpdateModel(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateModelMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateModel>>>
    export type ApiUpdateModelMutationBody = BodyType<UpdateModelDto>
    export type ApiUpdateModelMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateModel = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateModel>>, TError,{id: string;data: BodyType<UpdateModelDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateModel>>,
        TError,
        {id: string;data: BodyType<UpdateModelDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiDeleteModel = (
    id: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      id = unref(id);
      
      return fetchApiInstance<ModelEntity>(
      {url: `/models/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getApiDeleteModelMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteModel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiDeleteModel>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiDeleteModel>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  apiDeleteModel(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiDeleteModelMutationResult = NonNullable<Awaited<ReturnType<typeof apiDeleteModel>>>
    
    export type ApiDeleteModelMutationError = ErrorType<BackendErrorEntity>

    export const useApiDeleteModel = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiDeleteModel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiDeleteModel>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getApiDeleteModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    