<script setup lang="ts">
// PROPS
import type { Badge, BreadcrumbLink } from '#ui/types';
import lodashCapitalize from 'lodash-es/capitalize';
import lodashCompact from 'lodash-es/compact';
import type { PropType } from 'vue';

// PROPS
defineProps({
    title: { type: String, required: true },
    badge: {
        type: [String, Number, Object] as PropType<string | number | Badge>,
        default: undefined,
    },
});

// COMPOSABLES
const route = useRoute();

// DATA
const breadcrumbLinks = computed<BreadcrumbLink[]>(() => [
    {
        label: 'Home',
        icon: 'i-heroicons-home',
        to: { name: 'index' },
    },
    ...lodashCompact(route.path.split('/')).map((section, index) => {
        const pathArray = route.path.split('/');
        const sectionIndex = index + 2;

        return {
            label: lodashCapitalize(section.replace('-', ' ')),
            to: pathArray.length === sectionIndex ? undefined : pathArray.slice(0, sectionIndex).join('/'),
        };
    }),
]);
</script>

<template>
    <UDashboardNavbar :title="title">
        <template #left>
            <div class="flex flex-col gap-1.5">
                <div class="flex flex-row gap-1.5">
                    <h1 v-if="title" class="flex min-w-0 items-center gap-1.5 font-semibold text-gray-900 dark:text-white">
                        <span class="truncate">{{ title }}</span>
                    </h1>
                    <div v-if="badge || $slots.badge" class="inline-flex items-center">
                        <slot name="badge">
                            <UBadge
                                v-if="badge"
                                v-bind="{
                                    size: 'xs',
                                    color: 'primary',
                                    variant: 'subtle',
                                    ...(typeof badge === 'string' || typeof badge === 'number' ? { label: badge } : badge),
                                }"
                            />
                        </slot>
                    </div>
                </div>
                <slot name="breadcrumb">
                    <UBreadcrumb
                        v-if="!!breadcrumbLinks && breadcrumbLinks.length > 1"
                        divider="/"
                        :links="breadcrumbLinks"
                        :ui="{ li: 'text-xs', base: 'font-normal' }"
                    />
                </slot>
            </div>
        </template>
        <template v-if="$slots.center" #center>
            <slot name="center"></slot>
        </template>
        <template v-if="$slots.right || $slots.center" #right>
            <slot name="right"></slot>
        </template>
    </UDashboardNavbar>
</template>
