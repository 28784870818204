import { createSharedComposable } from '@vueuse/core';

export type DeviceType = {
    isAndroid: boolean;
    backgroundSyncSupport: boolean;
};

export type UseDeviceReturn = ComputedRef<DeviceType>;

const _useDevice = (): UseDeviceReturn => {
    const userAgent = computed<string>(() => {
        if (import.meta.server) {
            const headers = useRequestHeaders();

            return headers['user-agent'];
        } else {
            return window.navigator.userAgent;
        }
    });

    return computed<DeviceType>(() => ({
        isAndroid: /android/i.test(userAgent.value),
        backgroundSyncSupport: 'serviceWorker' in navigator && 'SyncManager' in window,
    }));
};

export const useDevice = createSharedComposable(_useDevice);
