import type { NotificationAction } from '#ui/types';

import { NotificationTimeout } from '~/types/notifications.type';

export type NotifyParams = {
    title: string;
    description?: string;
    icon?: string;
    timeout?: NotificationTimeout;
    clickHandler?: (...args: any[]) => void;
    actions?: NotificationAction[];
};

export type UseNotificationReturn = {
    notifyError: (params: NotifyParams) => void;
    notifySuccess: (params: NotifyParams) => void;
    notifyInfo: (params: NotifyParams) => void;
    notifyWarning: (params: NotifyParams) => void;
};

export const useNotification = (): UseNotificationReturn => {
    const toast = useToast();

    return {
        notifyError: ({ title, description, timeout, clickHandler, actions }): void => {
            toast.add({
                title,
                description,
                color: 'red',
                icon: 'i-heroicons-x-circle-16-solid',
                timeout: timeout ?? NotificationTimeout.Standard,
                click: clickHandler,
                actions: actions,
            });
        },

        notifySuccess: ({ title, description, icon = 'i-heroicons-check-circle', timeout, clickHandler, actions }): void => {
            toast.add({
                title,
                description,
                color: 'green',
                icon,
                timeout: timeout ?? NotificationTimeout.Standard,
                click: clickHandler,
                actions,
            });
        },

        notifyInfo: ({ title, description, icon = 'i-heroicons-information-circle', timeout, clickHandler, actions }): void => {
            toast.add({
                title,
                description,
                color: 'blue',
                icon,
                timeout: timeout ?? NotificationTimeout.Standard,
                click: clickHandler,
                actions,
            });
        },

        notifyWarning: ({ title, description, icon = 'i-heroicons-exclamation-triangle', timeout, clickHandler, actions }): void => {
            toast.add({
                title,
                description,
                color: 'yellow',
                icon,
                timeout: timeout ?? NotificationTimeout.Standard,
                click: clickHandler,
                actions,
            });
        },
    };
};
