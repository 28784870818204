import type { NetworkMode } from '@tanstack/vue-query';
import type { ComputedRef } from 'vue';

import { VUE_QUERY_DEFAULT_STALE_TIME } from '~/constants/vueQuery';

type UseApiOfflineUtilsReturn = {
    queryOptions: {
        staleTime: ComputedRef<number>;
        networkMode: ComputedRef<NetworkMode>;
    };
    mutationOptions: {
        networkMode: ComputedRef<NetworkMode>;
    };
};

export const useApiOfflineUtils = (): UseApiOfflineUtilsReturn => {
    // COMPOSABLES
    const isOnline = useOnline();

    // DATA
    const networkMode = computed<NetworkMode>(() => (isOnline.value ? 'online' : 'offlineFirst'));
    const staleTime = computed<number>(() => (isOnline.value ? VUE_QUERY_DEFAULT_STALE_TIME : Infinity));

    return {
        queryOptions: {
            networkMode,
            staleTime,
        },
        mutationOptions: {
            networkMode,
        },
    };
};
