import type { Ref } from 'vue';

type UseAuthReturn = ReturnType<typeof useAuth>;
type UseAuthStateReturn = ReturnType<typeof useAuthState>;
type Login = (email: string, password: string) => ReturnType<UseAuthReturn['signIn']>;

type UseAuthUtilsReturn = UseAuthReturn & {
    state: UseAuthStateReturn;
    login: Login;
    logout: () => void;
    isLoggedIn: Ref<boolean>;
};

export const useAuthUtils = (): UseAuthUtilsReturn => {
    const auth = useAuth();
    const authState = useAuthState();
    const { clearToken, data: stateData } = useAuthState();

    const login: Login = async (email, password) => {
        try {
            await auth.signIn({ email, password });
        } catch (error) {
            // TODO: quick fix to catch successful login with broken redirect by nuxt-auth.
            //  For the whole crazy story see https://adorea.atlassian.net/browse/BSD-440
            if (!isLoggedIn.value) throw error;
            navigateTo({ name: 'index' });
        }
    };

    const logout = (): void => {
        clearToken();
        stateData.value = null;
        navigateTo({ name: 'login' });
    };

    const isLoggedIn = ref<boolean>(auth.status.value === 'authenticated');

    watchEffect(() => {
        if (auth.status.value === 'authenticated') isLoggedIn.value = true;
        else if (auth.status.value === 'unauthenticated') isLoggedIn.value = false;
    });

    return {
        ...auth,
        state: authState,
        login,
        logout,
        isLoggedIn,
    };
};
