import type { VueQueryPluginOptions } from '@tanstack/vue-query';
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query';

import { VUE_QUERY_DEFAULT_STALE_TIME } from '~/constants/vueQuery';

export default defineNuxtPlugin({
    name: 'vueQuery',
    setup(nuxtApp) {
        const queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    retry: false,
                    refetchOnWindowFocus: false,
                    staleTime: VUE_QUERY_DEFAULT_STALE_TIME, // staleTime 0 prevents requests deduplication
                    // Other default values:
                    // - cacheTime: 5 min
                },
            },
        });
        const options: VueQueryPluginOptions = { queryClient, enableDevtoolsV6Plugin: true };

        nuxtApp.vueApp.use(VueQueryPlugin, options);
    },
});
