import lodashRemove from 'lodash-es/remove';

import {
    type BusDossierEntity,
    type BusDossierMediaEntity,
    type BusDossierMediaEntityMetaEntry,
    BusDossierMediaProcessingStatus,
    type CreateBusDossierBodyDto,
    type MediaType,
    type UpdateBusDossierBodyDto,
} from '~/apiClient';

const OFFLINE_PREFIX = 'offline';

export const isOfflineBusDossierId = (id: string): boolean => id.split('_')[0] === OFFLINE_PREFIX;

export const offlineBusDossierId = (vin: string): string => `${OFFLINE_PREFIX}_${vin}`;

export const offileBusDossierVin = (id: string): string => id.split('_')[1];

type OptimisticBusDossierMediaProps = {
    media: BusDossierMediaEntity[];
    image: Blob;
    name: string;
    position: number;
    type: MediaType;
};

export const optimisticBusDossierMedia = ({ media, image, name, position, type }: OptimisticBusDossierMediaProps): BusDossierMediaEntity[] => {
    const m = [...media];
    const meta: BusDossierMediaEntityMetaEntry = { name, position, url: URL.createObjectURL(image), status: BusDossierMediaProcessingStatus.Waiting };
    const mediaEntity = lodashRemove(m, me => me.type === type).pop() ?? { meta: [], type };
    const meMeta = lodashRemove(mediaEntity.meta, m => m.name === name).pop() ?? meta;
    mediaEntity.meta = [...mediaEntity.meta, { ...meMeta, ...meta }];

    return [...m, mediaEntity];
};

export const optimisticBusDossier = (
    id: string,
    data: Partial<(CreateBusDossierBodyDto | UpdateBusDossierBodyDto) & { media: BusDossierEntity['media'] }>
): BusDossierEntity => {
    return {
        id: id,
        createdAt: new Date().toString(),
        ...data,
        updatedAt: new Date().toString(),
    } as BusDossierEntity;
};
